import { TemplateElement } from '../../01-base/TemplateElement';
import styles from './WorkspaceSelector.css';

class WorkspaceSelector extends TemplateElement {
    constructor() {
        super(null, { autoRender: true, deferRender: true, styles: [styles] });
    }

    hooks() {
        return {
            connected: () => {
                this.contextPath = this.url.replace('/services/workspaces', '');
                this.getWorkspaces();
            },
        };
    }

    properties() {
        return {
            url: '',
            workspaces: [],
        };
    }

    template() {
        return `

            <div class="global-styles position-relative d-none d-lg-flex align-items-center workspace-wrapper mr-lg-2">
                <div class="dropdown-button d-flex align-items-center justify-content-between w-100 pl-2" data-select-button>
                    <div class="mr-2 h-100">${this.currentSelectedWorkspace.label}</div>
                    <svg ref="arrowIcon" class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 23 13">
                        <polygon
                            class="fill-current"
                            fill-rule="evenodd"
                            fill="currentColor"
                            points="7.499 10 5 13.123 16.249 22.123 27.499 13.123 25 10 16.249 17"
                            transform="translate(-5 -10)"
                        />
                    </svg>
                </div>
                <div ref="dropdownItems" class="dropdown position-absolute flex-column w-100 pl-2">
                     ${this.workspaces
                         .map(workspace => {
                             return `
                            <div class="select-item my-1 ${workspace.selected ? 'selected' : ''}" data-id="${
                                 workspace.id
                             }"
                                 data-select-item >
                              ${workspace.label}
                            </div>
                        `;
                         })
                         .join('')}
                </div>
            </div>
        `;
    }

    events() {
        return {
            '[data-select-button]': {
                click: e => {
                    this.$refs.dropdownItems.classList.toggle('show');
                    this.$refs.arrowIcon.classList.toggle('rotate');
                },
            },
            '[data-select-item]': {
                click: e => {
                    if (!e.target.classList.contains('selected')) {
                        const workspaceID = e.target.dataset.id;
                        this.sendSelectedWorkspace(workspaceID);
                    }
                    this.$refs.dropdownItems.classList.toggle('show');
                    this.$refs.arrowIcon.classList.toggle('rotate');
                },
            },
        };
    }

    computed() {
        return {
            currentSelectedWorkspace: () => {
                return this.workspaces.find(workspace => workspace.selected === true);
            },
        };
    }

    getWorkspaces() {
        fetch(this.url)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                this.workspaces = response;
            });
    }

    sendSelectedWorkspace(id) {
        const url = `${this.url}?id=${id}`;
        fetch(url).then(data => {
            if (data.status === 200 && data.ok === true) {
                location.href = location.href + '?dgvautoplay=1';
            } else {
                console.log('GET Request fail!');
            }
        });
    }
}

customElements.define('workspace-selector', WorkspaceSelector);
